import classNames from 'classnames/bind';
import React from 'react';

import styles from './AnimatedContentSwitcher.module.scss';

const cx = classNames.bind(styles);

interface IProps {
  children: React.ReactNode;
}

export const AnimatedContentSwitcher: React.FC<IProps> = ({ children }) => {
  const endAnimationTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const animationTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const [visibleChildren, setVisibleChildren] = React.useState(children);
  const [state, setState] = React.useState<{
    isAnimating: boolean;
    isMounted: boolean;
  }>({ isAnimating: false, isMounted: true });

  const resetAnimation = () => {
    if (endAnimationTimeoutRef.current) clearTimeout(endAnimationTimeoutRef.current);
    if (animationTimeoutRef.current) clearTimeout(animationTimeoutRef.current);

    setState({ ...state, isAnimating: false, isMounted: false });
  };

  React.useEffect(() => {
    resetAnimation();

    animationTimeoutRef.current = setTimeout(() => {
      setState({ isAnimating: true, isMounted: false });
      setVisibleChildren(children);
    }, 20);

    endAnimationTimeoutRef.current = setTimeout(() => {
      setState({ isAnimating: false, isMounted: true });
    }, 520);
  }, [children]);

  return (
    <div className={cx('wrapper', { isAnimating: state.isAnimating, isMounted: state.isMounted })}>
      {visibleChildren}
    </div>
  );
};
