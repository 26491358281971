import classNames from 'classnames/bind';
import React from 'react';

import type { IImage } from '@components/Image/Image';
import { Image } from '@components/Image/Image';
import { Text } from '@components/Text/Text';

import styles from './AboutUsMainContentItem.module.scss';

const cx = classNames.bind(styles);

interface Props {
  listItems: readonly { title: string; description: JSX.Element }[];
  reverse?: boolean;
  image: IImage;
}

export const AboutUsMainContentItem: React.FC<Props> = ({ listItems, reverse = false, image }) => (
  <div className={cx('wrapper', { reverse })}>
    <ul className={styles.list}>
      {listItems.map(({ title, description }) => (
        <li key={title} className={styles.listItem}>
          <Text
            className={styles.title}
            color="gray900"
            display="block"
            size={30}
            tag="strong"
            weight="bold"
          >
            {title}
          </Text>
          <Text color="gray500" tag="p">
            {description}
          </Text>
        </li>
      ))}
    </ul>
    <figure className={styles.imageWrapper}>
      <Image alt="" className={styles.image} height={400} src={image} width={400} />
    </figure>
  </div>
);
