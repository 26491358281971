/* eslint-disable @typescript-eslint/no-floating-promises */
import { useSpring, animated } from '@react-spring/web';
import classNames from 'classnames/bind';
import React from 'react';

import { Container } from '@components/Layout/Container/Container';
import { useWindowSize } from '@hooks/useWindowSize';

import styles from './LinesBackground.module.scss';

const calc = (x: number, y: number) => [x, y];

function getAngleDeg(aX: number, aY: number, bX: number, bY: number) {
  const angleRad = Math.atan2(aY - bY, aX - bX);
  const angleDeg = angleRad * (180 / Math.PI);

  return angleDeg;
}

const followCursor = (pointX: number, pointY: number, cursorX: number, cursorY: number) => {
  const angle = getAngleDeg(pointX, pointY, cursorX, cursorY);
  const scale = (Math.abs(pointX - cursorX) + Math.abs(pointY - cursorY)) / 800;

  const getScale = (size: number) => {
    if (size < 0.1) return 0.1;
    if (size > 1.1) return 1.1;

    return size;
  };

  return `translate3d(${cursorX / 50}px, ${
    cursorY / 50
  }px, 0) rotate(${angle}deg) scaleX(${getScale(scale)})`;
};

const lines = [
  { x: 201, y: 119 },
  { x: 56, y: 510 },
  { x: 33, y: 864 },
  { x: -150, y: 370 },
  { x: -200, y: 670 },
  { x: 1172, y: 827 },
  { x: 866, y: 976 },
  { x: 1206, y: 136 },
  { x: 1358, y: 700 },
];

const wideScreenExtraLines = [
  { x: -301, y: 159 },
  { x: -620, y: 480 },
  { x: -500, y: 900 },
  { x: 1500, y: 120 },
  { x: 1260, y: 420 },
  { x: 1700, y: 520 },
  { x: 1600, y: 820 },
];

const cx = classNames.bind(styles);

export const LinesBackground: React.FC = () => {
  const [isMounted, setIsMounted] = React.useState(false);
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 1, tension: 280, friction: 60 },
  }));
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const { width } = useWindowSize();

  React.useEffect(() => {
    set({ xy: calc(window.innerWidth / 2, window.innerHeight / 2) });

    document.addEventListener('mousemove', ({ clientX: x, clientY: y }) => {
      set({ xy: calc(x, y) });
    });

    const elementInterval = setInterval(() => {
      if (containerRef.current) {
        clearInterval(elementInterval);

        setTimeout(() => {
          setIsMounted(true);
        }, 300);
      }
    });

    return () => {
      document.removeEventListener('mousemove', ({ clientX: x, clientY: y }) => {
        set({ xy: calc(x, y) });
      });
      clearInterval(elementInterval);
    };
  }, []);

  const offsetX = React.useMemo(() => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      const { innerWidth } = window;

      return (innerWidth - width) / 2;
    }

    return 0;
  }, [isMounted, width]);

  const linesToRender = React.useMemo(() => {
    if (!width) return lines;

    if (width > 1500) {
      return [...lines, ...wideScreenExtraLines];
    }

    if (width < 1220) {
      return lines.map(line => ({ ...line, x: line.x - 150 }));
    }

    return lines;
  }, [width]);

  return (
    <div className={cx('wrapper', { isVisible: isMounted || !width })}>
      <Container>
        <div ref={containerRef} className={styles.container}>
          {isMounted &&
            width &&
            width >= 960 &&
            linesToRender.map(line => (
              <animated.div
                key={line.x + line.y}
                className={styles.point}
                style={{
                  top: line.y,
                  left: line.x,
                  transform: props.xy.to((x, y) =>
                    followCursor(line.x + offsetX, line.y + 35, x, y),
                  ),
                }}
              >
                <div className={styles.line} />
              </animated.div>
            ))}
        </div>
      </Container>
    </div>
  );
};
