import React from 'react';

import { Container } from '@components/Layout/Container/Container';
import { LogosRow } from '@components/LogosRow/LogosRow';
import { Padding } from '@components/Padding/Padding';

import styles from './AboutUsLogoRow.module.scss';
import eslLogo from '@logos/Black/esl.svg';
import avastLogo from '@logos/Colored/avast.svg';
import banijayLogo from '@logos/Colored/banijay.svg';
import esetLogo from '@logos/Colored/eset.svg';
import sportOneLogo from '@logos/Colored/sport1.svg';
import udemyLogo from '@logos/Colored/udemy.svg';

export const AboutUsLogoRow: React.FC = () => (
  <Padding className={styles.wrapper} size={{ x: 0, y: 40 }}>
    <Container>
      <LogosRow
        logos={[
          { src: banijayLogo, name: 'Banijay', width: 90, height: 25 },
          { src: eslLogo, name: 'ESL', width: 71, height: 22 },
          { src: avastLogo, name: 'Avast', width: 94, height: 29 },
          { src: udemyLogo, name: 'Udemy', width: 81, height: 30 },
          { src: sportOneLogo, name: 'Sport1', width: 92, height: 26 },
          { src: esetLogo, name: 'Eset', width: 69, height: 24 },
        ]}
        note="Trusted by"
      />
    </Container>
  </Padding>
);
