'use client';

import React from 'react';

import { Image } from '@components/Image/Image';
import { Container } from '@components/Layout/Container/Container';
import { Section } from '@components/Section/Section';

import styles from './CtaPanelWrapper.module.scss';
import ctaGlobeImage from './cta-globe.png';
import ctaLinesImage from './cta-lines.svg';

export interface Props {
  background?: 'globe' | 'lines';
  className?: string;
  children?: React.ReactNode;
}

export const CtaPanelWrapper: React.FC<Props> = ({ background = 'globe', className, children }) => (
  <Section overflow="hidden" padding={0} withContainer={false}>
    <Container className={className}>
      <Section borderRadius="rounded" className={styles.section} padding={90} isPulled>
        {background === 'globe' && (
          <figure className={styles.background}>
            <Image alt="" height={569} src={ctaGlobeImage} width={569} />
          </figure>
        )}
        {background === 'lines' && (
          <img
            alt=""
            className={styles.linesBackground}
            height={855}
            src={ctaLinesImage}
            width={1110}
          />
        )}
        <div className={styles.content}>{children}</div>
      </Section>
    </Container>
  </Section>
);
