import janHorakPhoto from '@images/People/jan-horak.jpg';
import janPracharPhoto from '@images/People/jan-prachar.jpg';
import jurajKacabaPhoto from '@images/People/juraj-kacaba.jpg';
import liborVanekPhoto from '@images/People/libor-vanek.jpg';
import marekFialaPhoto from '@images/People/marek-fiala.jpg';
import michalHosnaPhoto from '@images/People/michal-hosna.jpg';
import tomasSykoraPhoto from '@images/People/tomas-sykora.jpg';
import travisCahillPhoto from '@images/People/travis-cahill.jpg';

export const teams = {
  'CDN Development': {
    text: [
      'The CDN development team consists of skilled admins and devs working closely together. Our job is to maintain superb uptime and stability while continuously improving our service. Mainly, we take care of edge servers, DNS servers, storages and related sub-services.',
      'CDN77 is based on highly modified open-source software. Thanks to our state-of-the-art monitoring system and latency-based routing, it all works flawlessly. We love exploring new technologies and strive to deliver prototypes quickly. However, we deploy stable versions for all clients only once it’s optimized and rock solid.',
    ],
    author: { name: 'Jan Prachar', photo: janPracharPhoto, role: 'Head of CDN Development' },
  },
  'Video Engineering': {
    text: [
      'Video engineering is part and parcel of the CDN development team. It is no exaggeration to say that video optimizations are reflected throughout the entire CDN infrastructure. ',
      "It starts with the Live video and VOD processing stack, through which we're offering a variety of video origin services that include encoding, transcoding, live to VOD and many others.",
      "The multilayer caching and its fine-tuning ensure the best possible HIT ratio and ability to store content as close as physically possible to end-users. And finally, it's the edge configuration where hundreds of tweaks are made with one goal - smooth video delivery.",
    ],
    author: {
      name: 'Michal Hosna',
      photo: michalHosnaPhoto,
      role: 'Head of Video Engineering',
    },
  },
  'Platform Development': {
    text: [
      'We’re the core team taking care of the CDN77 API used by our clients, as well as API built directly in the Client Panel. We always make sure to monitor and identify any potential issues before they even occur.',
      'We maintain a perfect balance between the latest and most stable technologies. Code quality is our priority.',
    ],
    author: [
      { name: 'Marek Fiala', photo: marekFialaPhoto, role: 'Head of Backend Development' },
      { name: 'Libor Vanek', photo: liborVanekPhoto, role: 'Head of Frontend Development' },
    ],
  },
  NetOps: {
    text: [
      "My team precisely plans and executes development of the CDN77 network. From access to the edge, physical fibers to DDoS protection – it's all our responsibility. Our network is fully redundant and it's based on Arista network hardware.",
      'There is one single priority above all that drives our decisions and steps while extending and maintaining our network – uncompromising quality, without exceptions. We consider the work we do as a way of life rather than a day job.',
    ],
    author: {
      name: 'Jan Horak',
      photo: janHorakPhoto,
      role: 'Head of Network',
    },
  },
  'Data centers Ops': {
    text: [
      "The DCs ops team takes care of CDN77 servers in data centers around the world. It's mostly about HW shipping, new server installations, cabling, SSDs, and other HW changes. We handle both carefully planned upgrades and also situations where immediate solutions are needed.",
      'Photos of our perfect cabling are popular on Reddit. No matter whether we use remote hands or travel to a location personally, any DC job needs to be fast and precise.',
    ],
    author: { name: 'Tomas Sykora', photo: tomasSykoraPhoto, role: 'Head of Data Centers Ops' },
  },
  'Client Solutions': {
    text: [
      'The client solution team combines sales and technical solution proposal services. We’re here for our clients through their entire experience with CDN77. Our primary role is to work with current, as well as future, clients, making sure our service brings value on both the technical and business levels to them.',
      'Making pricing and solution proposals, working closely with clients, as well as technical teams, within CDN77 gives us a great chance to gain a decent technical knowledge while understanding the market dynamics, which we find refreshingly challenging and enjoyable.',
    ],
    author: {
      name: 'Juraj Kacaba',
      photo: jurajKacabaPhoto,
      role: 'Head of Client Implementations',
    },
  },
  'Tech Support': {
    text: [
      'We’re here to provide real help and peace of mind for our clients. From simply describing various CDN77 features to debugging or configuring systems, each member of the support team perfectly knows the service, internal processes, and is skilled to help resolve technical issues of any kind.',
      'Tech support works closely with all CDN77 technical and business departments to provide the most up-to-date information and be able to react in real time.',
    ],
    author: { name: 'Travis Cahill', photo: travisCahillPhoto, role: 'Head of Support' },
  },
};

export const labelForMobile = [
  'CDN Dev',
  'Network Dev',
  'Data centers Ops',
  'Client Solutions',
  'Frontend Dev',
  'Backend Dev',
  'Tech Support',
];
