import React from 'react';

import { Text } from '@components/Text/Text';
import { cdnStats } from '@siteData';

import styles from './AboutUsStats.module.scss';

const stats = [
  {
    value: cdnStats.capacity,
    description: 'network with private backbone',
  },
  {
    value: cdnStats.trafficPeak,
    description: 'daily traffic peaks',
  },
  {
    value: cdnStats.dailyTraffic,
    description: 'of data delivered daily',
  },
  {
    value: '$145MM',
    description: 'annual revenue in 2023',
  },
  {
    value: `${cdnStats.yearsOnMarket} years`,
    description: 'on the market',
  },
  {
    value: '1000+',
    description: 'satisfied clients',
  },
] as const;

export const AboutUsStats: React.FC = () => (
  <ul className={styles.wrapper}>
    {stats.map(({ value, description }) => (
      <li key={value}>
        <Text
          align="center"
          className={styles.title}
          color="gray900"
          display="block"
          size={44}
          tag="strong"
          weight="regular"
        >
          {value}
        </Text>
        <Text align="center" color="gray500" display="block" opacity={70} size={18} tag="p">
          {description}
        </Text>
      </li>
    ))}
  </ul>
);
