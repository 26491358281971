import React from 'react';

import { AboutUsMainContentItem } from './AboutUsMainContentItem/AboutUsMainContentItem';
import { Container } from '@components/Layout/Container/Container';

import styles from './AboutUsMainContent.module.scss';
import peopleImage from './people.jpg';
import rackImage from './rack.jpg';

export const AboutUsMainContent: React.FC = () => (
  <div className={styles.wrapper}>
    <Container>
      <AboutUsMainContentItem
        image={peopleImage}
        listItems={[
          {
            title: 'Communication close to our hearts',
            description: (
              <>
                At every level of our communication, honesty and transparency are paramount. With
                that in mind, all inquiries are answered 24/7 by skilled engineers with a detailed
                understanding of your use case.
              </>
            ),
          },
          {
            title: 'Long-term partnership is our way to go',
            description: (
              <>
                Our clients stay with us because of the satisfaction with our service, not because
                of contractual obligations. This enduring commitment speaks volumes about their
                trust in our services and the value we consistently deliver for over a decade.
              </>
            ),
          },
        ]}
      />
      <AboutUsMainContentItem
        image={rackImage}
        listItems={[
          {
            title: 'Consistency in performance',
            description: (
              <>
                Our commitment to reliability can be seen in a robust network setup with a global
                private backbone and modern hardware that not only meet current demands but are
                built with future challenges in mind.
              </>
            ),
          },
          {
            title: 'Tailored for your business',
            description: (
              <>
                Our teams of engineers are creating customized solutions every day for clients from
                diverse industries. We are not just following trends, we innovate one line of code
                at a time.
              </>
            ),
          },
        ]}
        reverse
      />
    </Container>
  </div>
);
