import React from 'react';

type IChangeTab = (index: number) => void;

export const useToggle = <T>(
  data: T[],
  defaultTab?: number,
  showAll?: boolean,
): {
  activeIndex: number;
  activeItem: T | T[];
  changeTab: IChangeTab;
} => {
  const [activeIndex, setActiveIndex] = React.useState(defaultTab ?? 0);

  const changeTab: IChangeTab = index => setActiveIndex(index);

  return {
    activeIndex,
    activeItem: showAll ? data : data[activeIndex],
    changeTab,
  };
};
