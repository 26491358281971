import React from 'react';

import { Executives } from './Executives/Executives';
import { OurTeam } from './OurTeam/OurTeam';
import { AnimatedPath } from '@components/AnimatedPath/AnimatedPath';
import { Section } from '@components/Section/Section';
import { Tabs } from '@components/Tabs/Tabs';
import { Text } from '@components/Text/Text';

export const TeamSection: React.FC = () => (
  <Section padding={{ top: 150, bottom: 170 }} theme="tiles">
    <Text align="center" className="mb-4" color="gray900" display="block" size={44} tag="h2">
      Meet the people behind CDN77
    </Text>
    <Text align="center" className="mb-5" display="block" size={18} tag="p">
      Dedicated professionals, pushing the web forward
    </Text>
    <AnimatedPath align="center" delayMs={0} durationMs={500} height={60} />
    <Tabs
      items={[
        { name: 'Executives', content: <Executives /> },
        {
          name: 'Teams',
          content: <OurTeam />,
        },
      ]}
      spacingBottom="md"
      tabbarMaxWidth={350}
      tabbarWidth="auto"
      tabsSize="lg"
    ></Tabs>
  </Section>
);
