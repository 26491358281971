import React from 'react';

import { Image } from '@components/Image/Image';
import { SimpleCard } from '@components/SimpleCard/SimpleCard';
import { Text } from '@components/Text/Text';

import styles from './Executives.module.scss';
import jkPhoto from './jk.jpg';
import jpPhoto from './jp.jpg';
import tbPhoto from './tb.jpg';
import tkPhoto from './tk.jpg';
import vsPhoto from './vs.jpg';
import zcPhoto from './zc.jpg';

const executivesData = [
  {
    name: 'Zdenek Cendra',
    position: 'Founder & CEO',
    image: zcPhoto,
  },
  {
    name: 'Jan Krpes',
    position: 'Chief Technology Officer',
    image: jkPhoto,
  },
  {
    name: 'Veronika Siskova',
    position: 'Chief Financial Officer',
    image: vsPhoto,
  },
  {
    name: 'Jiri Prochazka',
    position: 'VP of Network Infrastructure',
    image: jpPhoto,
  },
  {
    name: 'Tomas Kvasnicka',
    position: 'VP of Engineering',
    image: tkPhoto,
  },
  {
    name: 'Tomas Bacik',
    position: 'VP of Sales & Client Solutions',
    image: tbPhoto,
  },
] as const;

export const Executives: React.FC = () => (
  <div className={styles.executivesWrapper}>
    {executivesData.map(({ name, position, image }) => (
      <SimpleCard key={name} className={styles.card} size={0} theme="primary">
        <div className={styles.cardText}>
          <Text color="gray900" size={18} tag="strong">
            {name}
          </Text>
          <Text color="gray500" tag="p">
            {position}
          </Text>
        </div>
        <div className={styles.imageWrapper}>
          <Image alt={name} className={styles.image} height={280} src={image} width={270} />
        </div>
      </SimpleCard>
    ))}
  </div>
);
