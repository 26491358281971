import classNames from 'classnames/bind';
import React from 'react';

import type { IImage } from '@components/Image/Image';
import { Image } from '@components/Image/Image';

import styles from './Author.module.scss';

export interface IAuthor {
  name: string;
  photo?: IImage;
  company?: string;
  role?: string;
}

export interface IProps extends IAuthor {
  className?: string;
  size: 'md' | 'lg' | 'xl';
  theme: 'square' | 'round';
  color?: 'dark' | 'light';
}

const sizes = {
  md: 60,
  lg: 90,
  xl: 120,
};

const cx = classNames.bind(styles);

export const Author: React.FC<IProps> = ({
  name,
  photo,
  company,
  role,
  className,
  size,
  theme,
  color = 'dark',
}) => (
  <div className={cx('wrapper', color, className, theme)}>
    {photo && (
      <div className={cx('imageWrapper', size)}>
        <Image
          alt={name}
          className={styles.avatar}
          height={sizes[size]}
          src={photo}
          width={sizes[size]}
        />
      </div>
    )}
    <div>
      <strong className={styles.name}>{name}</strong>
      <span className={styles.company}>
        {role}
        {role && company && ' @ '}
        {company}
      </span>
    </div>
  </div>
);
