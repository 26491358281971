import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';

import styles from './Tabbar.module.scss';

interface ILinkProps<T> {
  items: { text: string; href?: string }[];
  activeTab: T;
}

interface IButtonProps<T> {
  items: T[];
  activeTab: T;
  onClick: (name: T) => void;
}

type IProps<T> = (ILinkProps<T> | IButtonProps<T>) & {
  width?: 'full' | 'auto';
  size?: 'md' | 'lg';
  theme?: 'light' | 'transparent-border';
  className?: string;
  maxWidth?: number;
};

const cx = classNames.bind(styles);

export const Tabbar = <T extends string>(props: IProps<T>) => {
  const scrollableTabsWrapperRef = React.useRef<HTMLUListElement | null>(null);

  const centerButton = (button: Element) => {
    const element = scrollableTabsWrapperRef?.current;

    if (element) {
      const { x, width } = button.getBoundingClientRect();
      const currentWrapperScrollLeft = element.scrollLeft;
      const container = element.getBoundingClientRect();
      const containerCenterPosition = container.left + container.width / 2;
      const tabCenterPosition = x + width / 2;
      const newX = currentWrapperScrollLeft + tabCenterPosition - containerCenterPosition;

      element.scrollLeft = newX;
    }
  };

  React.useEffect(() => {
    const element = scrollableTabsWrapperRef?.current;

    if (element) {
      const activeButton = element.querySelector('[data-active]');

      if (activeButton) {
        centerButton(activeButton);
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.activeTab]);

  const tabs = React.useMemo(() => {
    if ('onClick' in props) {
      const { items, onClick, activeTab, theme = 'light', size = 'md' } = props;

      return items.map(tab => (
        <li key={tab} className={styles.tabWrapper} data-active={tab === activeTab || undefined}>
          <Button
            className={cx('button', theme, `size-${size}`, { isActive: tab === activeTab })}
            theme="link"
            variant="inherit"
            onClick={() => onClick(tab)}
          >
            {tab}
          </Button>
        </li>
      ));
    }

    const { items, activeTab, theme = 'light', size = 'md' } = props;

    return items.map(({ text, href }) => (
      <li key={href} className={styles.tabWrapper} data-active={href === activeTab || undefined}>
        <Button
          className={cx('button', theme, `size-${size}`, { isActive: href === activeTab })}
          href={href}
          theme="link"
          variant="inherit"
        >
          {text}
        </Button>
      </li>
    ));
  }, [props]);

  return (
    <ul
      ref={scrollableTabsWrapperRef}
      className={cx('tabsRow', props.theme, props.width ?? 'full', props.className)}
      style={{ maxWidth: props.maxWidth }}
    >
      {tabs}
    </ul>
  );
};
