import React from 'react';

import { HalfNoiseBackground } from '@components/HalfNoiseBackground/HalfNoiseBackground';
import { Image } from '@components/Image/Image';
import { Container } from '@components/Layout/Container/Container';
import { Parallax } from '@components/Parallax/Parallax';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';

import styles from './TechEnthusiastsSection.module.scss';
import globeImage from './globe.png';

export const TechEnthusiastsSection: React.FC = () => (
  <HalfNoiseBackground height="half" position="end">
    <Container>
      <Parallax intensityThrottle={40}>
        <Section
          borderRadius="rounded"
          className={styles.wrapper}
          padding={{ top: 90, bottom: 120 }}
          theme="dark"
        >
          <div className={styles.content}>
            <Text
              className="mb-6"
              color="white"
              display="block"
              maxWidth={500}
              size={44}
              tag="h2"
              weight="regular"
            >
              We are tech enthusiasts to the core
            </Text>
            <Text color="white" maxWidth={475} opacity={70} size={18} tag="p">
              We are powered by industry experts, passionate talents, and true tech enthusiasts.
              Representing us at top technology conferences worldwide, they're at the forefront of
              research and innovation, always pushing the boundaries of what’s possible.
            </Text>
          </div>
          <Image alt="" className={styles.image} height={526} src={globeImage} width={506} />
        </Section>
      </Parallax>
    </Container>
  </HalfNoiseBackground>
);
