import classNames from 'classnames/bind';
import React from 'react';

import { Tabbar } from './Tabbar/Tabbar';
import { AnimatedContentSwitcher } from '@components/AnimatedContentSwitcher/AnimatedContentSwitcher';

import styles from './Tabs.module.scss';

interface IProps {
  items: { name: string; content: JSX.Element }[];
  className?: string;
  spacingBottom: 'sm' | 'md' | 'lg';
  tabbarWidth?: 'full' | 'auto';
  tabsSize?: 'md' | 'lg';
  tabbarMaxWidth?: number;
}

const cx = classNames.bind(styles);

export const Tabs: React.FC<IProps> = ({
  items,
  className,
  spacingBottom = 'lg',
  tabbarWidth = 'full',
  tabsSize = 'md',
  tabbarMaxWidth,
}) => {
  const [activeTab, setActiveTab] = React.useState(items[0].name);

  const content = React.useMemo(
    () => items.find(({ name }) => name === activeTab)?.content ?? null,
    [activeTab],
  );

  return (
    <div>
      <div className={cx('tabbarWrapper', className, `spacing-${spacingBottom}`)}>
        <Tabbar
          activeTab={activeTab}
          items={items.map(({ name }) => name)}
          maxWidth={tabbarMaxWidth}
          size={tabsSize}
          width={tabbarWidth}
          onClick={setActiveTab}
        />
      </div>
      <AnimatedContentSwitcher>{content}</AnimatedContentSwitcher>
    </div>
  );
};
