import classNames from 'classnames/bind';
import React from 'react';

import { useToggle } from './useToggle';
import { AnimatedContentSwitcher } from '@components/AnimatedContentSwitcher/AnimatedContentSwitcher';
import { SwitchButton } from '@components/SwitchButton/SwitchButton';

import styles from './ContentToggle.module.scss';

interface IProps {
  data: Record<string, JSX.Element>;
  className?: string;
}

const cx = classNames.bind(styles);

export const ContentToggle: React.FC<IProps> = ({ data, className }) => {
  const { activeIndex, activeItem, changeTab } = useToggle(Object.values(data));
  const scrollableTabsWrapperRef = React.useRef<HTMLUListElement | null>(null);

  const centerButton = (button: Element) => {
    const element = scrollableTabsWrapperRef?.current;

    if (element) {
      const { x, width } = button.getBoundingClientRect();
      const currentWrapperScrollLeft = element.scrollLeft;
      const container = element.getBoundingClientRect();
      const containerCenterPosition = container.left + container.width / 2;
      const tabCenterPosition = x + width / 2;
      const newX = currentWrapperScrollLeft + tabCenterPosition - containerCenterPosition;

      element.scrollLeft = newX;
    }
  };

  React.useEffect(() => {
    const element = scrollableTabsWrapperRef?.current;

    if (element) {
      const activeButton = element.querySelector('[data-active]');

      if (activeButton) {
        centerButton(activeButton);
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [activeIndex]);

  return (
    <div className={cx('wrapper', className)}>
      <nav className={styles.nav}>
        <ul ref={scrollableTabsWrapperRef} className={styles.list}>
          {Object.keys(data).map((label, index) => (
            <li
              key={label}
              className={styles.item}
              data-active={index === activeIndex || undefined}
            >
              <SwitchButton
                isActive={index === activeIndex}
                theme="secondary"
                onClick={() => changeTab(index)}
              >
                {label}
              </SwitchButton>
            </li>
          ))}
        </ul>
      </nav>
      <div className={styles.content}>
        <AnimatedContentSwitcher>{activeItem}</AnimatedContentSwitcher>
      </div>
    </div>
  );
};
