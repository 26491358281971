import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';

import styles from './SwitchButton.module.scss';

type IProps = {
  theme: 'primary' | 'secondary';
  href?: string;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  isActive?: boolean;
  className?: string;
  children: string;
};

const cx = classNames.bind(styles);

export const SwitchButton: React.FC<IProps> = ({
  href,
  isActive,
  theme,
  className,
  onClick,
  children,
}) => (
  <div className={cx('wrapper', theme, className, { isActive })}>
    <Button
      className={cx('button', theme)}
      href={href}
      theme="link"
      variant="inherit"
      onClick={onClick}
    >
      {children}
    </Button>
  </div>
);
