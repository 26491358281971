import React from 'react';
import { throttle } from 'throttle-debounce';

import useEventListener from '@hooks/useEventListener';

interface IProps {
  intensityThrottle?: number;
  children: React.ReactNode;
}

const THROTTLE_MS = 100;

export const Parallax: React.FC<IProps> = ({ intensityThrottle = 10, children }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const handleScroll = throttle(THROTTLE_MS, () => {
    if (window.innerWidth < 992) return;

    const screenMiddle = window.scrollY + window.innerHeight / 2;

    const scrollTop = document.documentElement.scrollTop ?? 0;
    const top = ref.current?.getBoundingClientRect().top ?? 0;
    const sectionMiddle = ref.current ? scrollTop + top + ref.current.offsetHeight / 2 : null;

    const diff = sectionMiddle ? (screenMiddle - sectionMiddle) * -1 : 0;

    if (ref.current) {
      ref.current.style.transform = `translateY(${diff / intensityThrottle}px)`;
    }
  });

  useEventListener('scroll', handleScroll);

  return (
    <div ref={ref} style={{ transition: `transform ${THROTTLE_MS}ms linear` }}>
      {children}
    </div>
  );
};
