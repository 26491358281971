import classNames from 'classnames/bind';
import React from 'react';

import type { ILogo } from '@components/Logo/Logo';
import { Logo } from '@components/Logo/Logo';
import { Text } from '@components/Text/Text';

import styles from './LogosRow.module.scss';

const cx = classNames.bind(styles);

interface IProps {
  logos: ILogo[];
  className?: string;
  note: string;
}

export const LogosRow: React.FC<IProps> = ({ logos, note }) => (
  <ul className={cx('wrapper', `count-${logos.length}`)}>
    <li className={styles.noteWrapper}>
      <Text
        className={styles.note}
        opacity={80}
        size={12}
        tag="strong"
        transform="uppercase"
        weight="medium"
      >
        {note}
      </Text>
    </li>
    {logos.map((logo, index) => (
      <li
        key={logo.name}
        className={styles.logoWrapper}
        style={{ animationDelay: `${index * 50}ms` }}
      >
        <Logo {...logo} />
      </li>
    ))}
  </ul>
);
