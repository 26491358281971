import React from 'react';

import { AboutUsHeader } from './AboutUsHeader/AboutUsHeader';
import { AboutUsLogoRow } from './AboutUsLogoRow/AboutUsLogoRow';
import { AboutUsMainContent } from './AboutUsMainContent/AboutUsMainContent';
import { TeamSection } from './TeamSection/TeamSection';
import { TechEnthusiastsSection } from './TechEnthusiastsSection/TechEnthusiastsSection';
import { CtaPanel } from '@components/CtaPanel/CtaPanel';
import { HalfNoiseBackground } from '@components/HalfNoiseBackground/HalfNoiseBackground';
import { Head } from '@components/Layout/Head';

export const AboutUs: React.FC = () => (
  <>
    <Head imageOg="https://www.cdn77.com/open-graph-about.png" title="About Us" />
    <AboutUsHeader />
    <AboutUsLogoRow />
    <AboutUsMainContent />
    <TechEnthusiastsSection />
    <TeamSection />

    <HalfNoiseBackground height="half" position="start">
      <CtaPanel background="lines" />
    </HalfNoiseBackground>
  </>
);
