import classNames from 'classnames/bind';
import React from 'react';

import styles from './Paragraph.module.scss';

interface IProps {
  tag?: 'p' | 'q';
  align?: 'left' | 'center' | 'right';
  color?: 'light' | 'default' | 'dark';
  size?: 'inherit' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  opacity?: 60 | 80 | 100;
  maxWidth?: number;
  isCentered?: boolean;
  className?: string;
  dangerouslySetInnerHTML?: { __html: string };
  children?: React.ReactNode;
}

const cx = classNames.bind(styles);

export const Paragraph: React.FC<IProps> = ({
  tag: Tag = 'p',
  align = 'left',
  color = 'default',
  size = 'md',
  opacity = 100,
  maxWidth,
  className,
  isCentered,
  dangerouslySetInnerHTML,
  children,
}) => (
  <Tag
    // eslint-disable-next-line react/no-danger
    className={cx('paragraph', align, color, size, `opacity-${opacity}`, className, { isCentered })}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    style={{ maxWidth }}
  >
    {children}
  </Tag>
);
