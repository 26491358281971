import NextHead from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { routes } from '@routes';

const APP_URL = process.env.NEXT_PUBLIC_APP_URL ?? '';

interface IProps {
  title: string;
  description?: string;
  canonical?: string;
  ogUrl?: string;
  imageTwitter?: string;
  imageOg?: string;
  noIndex?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export const Head: React.FC<IProps> = ({
  description,
  title,
  noIndex,
  canonical,
  ogUrl,
  imageOg,
  imageTwitter,
  children,
}) => {
  const router = useRouter();

  return (
    <NextHead>
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <title>{`${title} | CDN77.com`}</title>
      <meta
        content={
          ogUrl
            ? `${APP_URL.replace(/\/+$/, '')}${ogUrl}`
            : router &&
              `${APP_URL.replace(/\/+$/, '')}${
                router && router.pathname !== '/' ? router.pathname : ''
              }`
        }
        property="og:url"
      />
      {description && (
        <>
          <meta content={description.replace(/\s\s+/g, ' ')} name="description" />
          <meta content={description.replace(/\s\s+/g, ' ')} property="og:description" />
          <meta content={description.replace(/\s\s+/g, ' ')} name="twitter:description" />
        </>
      )}
      {noIndex ? (
        <meta content="noindex, follow" name="robots" />
      ) : (
        router &&
        router.pathname !== '_error' && (
          <link
            href={
              canonical
                ? `${APP_URL.replace(/\/+$/, '')}${canonical}`
                : router &&
                  `${APP_URL.replace(/\/+$/, '')}${router.pathname !== '/' ? router.pathname : ''}`
            }
            rel="canonical"
          />
        )
      )}
      {router && ![routes.tlsTest].includes(router.pathname) && (
        <>
          <meta content={`${title} | CDN77.com`} property="og:title" />
          <meta content={`${title} | CDN77.com`} name="twitter:title" />
          <meta content="summary" name="twitter:card" />
          <meta content={imageOg ?? 'https://www.cdn77.com/open-graph.png'} property="og:image" />
          {imageOg && <meta content="1200" property="og:image:width" />}
          {imageOg && <meta content="628" property="og:image:height" />}
          <meta
            content={imageTwitter ?? 'https://www.cdn77.com/open-graph.png'}
            name="twitter:image"
          />
        </>
      )}
      {children}
    </NextHead>
  );
};
