import classNames from 'classnames/bind';
import React from 'react';

import styles from './HalfNoiseBackground.module.scss';

interface IProps {
  className?: string;
  position?: 'start' | 'end';
  height?: 'half' | 'quarter';
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

export const HalfNoiseBackground: React.FC<IProps> = ({
  className,
  position = 'start',
  height = 'half',
  children,
}) => <div className={cx('wrapper', position, height, className)}>{children}</div>;
