import classNames from 'classnames/bind';
import { useInView } from 'framer-motion';
import React from 'react';

import styles from './AnimatedPath.module.scss';

type Props = {
  delayMs?: number;
  durationMs?: number;
  className?: string;
  align?: 'none' | 'center';
  color?: 'light' | 'dark';
} & ({ path: JSX.Element } | { height: number });

const cx = classNames.bind(styles);

export const AnimatedPath: React.FC<Props> = ({
  delayMs = 500,
  durationMs = 1000,
  align = 'none',
  color = 'dark',
  className,
  ...props
}) => {
  const squareRef = React.useRef<HTMLDivElement>(null);
  const pathWrapperRef = React.useRef<HTMLDivElement>(null);
  const isInView = useInView(pathWrapperRef, { amount: 'all' });

  React.useEffect(() => {
    if (!isInView) return;

    const pathWrapper = pathWrapperRef?.current;

    if (pathWrapper) {
      const svg = pathWrapper.querySelector('svg');
      const path = svg?.querySelector('path');

      if (svg && path) {
        const pathPosition = path.getBoundingClientRect();
        const pathTotalLength = 'path' in props ? path.getTotalLength() : props.height;
        svg.style.strokeDasharray = pathTotalLength.toFixed(0);
        svg.style.strokeDashoffset = pathTotalLength.toFixed(0);
        svg.style.opacity = '1';

        const positionElements = () => {
          const pointPercentage = 'path' in props ? pathPosition.height : props.height;
          const pointOnPath = pointPercentage * pathTotalLength;
          const pathPoint = path.getPointAtLength(pointOnPath);

          if (squareRef.current) {
            squareRef.current.style.transform = `translate(${pathPoint.x}px, ${
              'path' in props ? pathPoint.y : props.height
            }px)`;
          }
        };

        positionElements();
      }
    }
  }, [isInView]);

  const path =
    'path' in props ? (
      props.path
    ) : (
      <svg
        height={props.height}
        viewBox={`0 0 1 ${props.height}`}
        width="1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={`M.5 0v${props.height}`} />
      </svg>
    );

  return (
    <div className={cx('wrapper', align, color, className)}>
      <div className={styles.inner}>
        <div ref={pathWrapperRef}>
          {React.cloneElement(path, {
            className: styles.path,
            style: { animationDelay: `${delayMs}ms`, animationDuration: `${durationMs}ms` },
          })}
        </div>
        {React.cloneElement(path, {
          className: styles.overlay,
        })}
        <div ref={squareRef} className={styles.squareWrapper}>
          <div className={styles.square} style={{ animationDelay: `${delayMs + durationMs}ms` }} />
        </div>
      </div>
    </div>
  );
};
