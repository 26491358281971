import React from 'react';

import { Author } from '@components/Author/Author';
import { ContentToggle } from '@components/ContentToggle/ContentToggle';
import { teams } from '@components/Pages/AboutUs/TeamSection/OurTeam/ourTeamData';
import { Paragraph } from '@components/Paragraph/Paragraph';

const transformedTeams = Object.entries(teams).reduce(
  (teamsWithJsx, [key, { text, author }]) => ({
    ...teamsWithJsx,
    [key]: (
      <div className="mw-6 mx-auto">
        {text.map((item, index) => (
          <Paragraph key={item} className="mb-3" size="lg">
            {index === 0 && '“'}
            {item}
            {index === text.length - 1 && '”'}
          </Paragraph>
        ))}
        {Array.isArray(author) ? (
          author.map(({ name, photo, role }, index) => (
            <Author
              key={name}
              className={index === 0 ? 'mt-6' : 'mt-2'}
              name={name}
              photo={photo}
              role={role}
              size="md"
              theme="square"
            />
          ))
        ) : (
          <Author
            className="mt-5"
            name={author.name}
            photo={author.photo}
            role={author.role}
            size="md"
            theme="square"
          />
        )}
      </div>
    ),
  }),
  {},
);

export const OurTeam: React.FC = () => (
  <div className="mw-9 mx-auto">
    <ContentToggle data={transformedTeams} />
  </div>
);
